import React from "react";
import { Box, Grid, Text, Button } from "@chakra-ui/react";
import { navigate } from "gatsby";

const ContactComponent = () => {
  return (
    <Box as="section">
      <Grid
        minH="600px"
        gridTemplateColumns={{ base: "none", md: "1fr 1fr" }}
        gridTemplateRows={{ base: "1fr 1fr", md: "none" }}
      >
        <Box
          display="flex"
          flexDir="column"
          backgroundColor="secondary.500"
          justifyContent="center"
          alignItems={{ base: "center", md: "flex-start" }}
          textAlign={{ base: "center", md: "initial" }}
          p={{ base: "24px", md: "6rem" }}
        >
          <Text
            fontSize={{ base: "22px", md: "28px", lg: "32px" }}
            lineHeight={{ base: "30px", md: "36px", lg: "40px" }}
            color="#ffffff"
            mb="6"
            fontWeight={600}
          >
            Connect with us to see how
            <br />
            we can help you!
          </Text>
          <Button
            onClick={() => navigate("/contact-us")}
            size="lg"
            colorScheme="primary"
            color="secondary.500"
          >
            Contact Us
          </Button>
        </Box>
        <Box
          backgroundImage="url('/img/aevias-contact.jpg')"
          bgRepeat="no-repeat"
          backgroundSize="cover"
        />
      </Grid>
    </Box>
  );
};

export default ContactComponent;
